<!-- 中心介绍 -->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top">
        <top-nav></top-nav>
      </div>
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
    </div>

    <div style="background-color: #f5f5f5;padding: 20px 0;">
      <div class="content_c">
        <div class="container">
          <!-- <a href="javascript:history.back(-1)">点击返回</a> -->
          <el-breadcrumb-item
            ><div style="cursor: pointer;" @click="getGo">《 返回上一级 </div>
          </el-breadcrumb-item>
          <div class="blog-detail">
            <div class="text mb-30">
              <h6 class="detail-title" v-if="isTitle">{{ detailsItem.title }}</h6>
              <p class="time" v-if="type">
                发布时间：{{ detailsItem.submitTime }}
              </p>
              <p v-html="detailsItem.contentEdit" class="rich"></p>
            </div>
        
          </div>
        </div>
      </div>
    </div>

    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import {
  getInfo
} from "../api/api";
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import {
  province
} from "../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      userInfo: "",
      detailsItem: {},
      pid: "",
      type: false, //是否显示发布时间
      isTitle: false //是否显示标题
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this.into();
  },

  methods: {
    getGo(){
      // history.back()
      this.$router.back()
    },
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    async into() {
      this.pid = this.$route.query.pid;
      let type = this.$route.query.type;
      let isTitle = this.$route.query.isTitle;
      console.log(isTitle, "isTitle");
      if (type == 1) {
        this.type = true;
      }
      if (isTitle == 1) {
        this.isTitle = true;
      }
      // console.log(this.$route.query,this.type,'ioio');
      if (!this.pid) {
        this.$router.push("/index");
      }
      let res = await getInfo({
        pid: this.pid,
      });
      if (res.ResultCode == 0) {
        // console.log(res.Data, "res详情");
        this.detailsItem = res.Data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rich {
  ::v-deep p {
    line-height: 3;
    text-align: left;
  }

  ::v-deep :is(h1, h2, h3, h4, h5, h6) {
    text-align: left;
  }

  ::v-deep table.MsoTableGrid.layui-table {
    margin: auto;
  }
}


.box {
  .nav_top {
    width: 100%;
    height: 50vh;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';
      transition: bounce-in 3s;

      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }

    .top {
      width: 100%;
      height: 6.25rem;
      background-size: 100% 6.25rem;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 4.125rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 8.75rem;
    }

    h2 {
      font-size: 2.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
  }

  h1 {
    font-size: 4.125rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-top: 8.75rem;
  }

  h2 {
    font-size: 2.5rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba($color: #ffffff, $alpha: 0.8);
    text-align: center;
    margin-top: 3.1875rem;
  }
}


.content_c {
  width: 87.5rem;
  margin: auto;
  border: .0625rem solid #e5e5e5;
  padding: 3.125rem 9.375rem;
  background-color: #fff;


    // 新闻列表

    .blog-detail .detail-title {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      margin: 1.25rem auto;
    }

    .blog-detail .text {
      width: 100%;
    }

    .blog-detail  .time {
      text-align: center;
      margin: 1.25rem auto;
    }

    p.rich {
      margin: auto;
      text-align: center;
    }

    .blog-detail {
      height: 100%;
      padding: 3.125rem auto;
      margin: 1.25rem 0;
      overflow: hidden;
    }

    p {
      text-indent: 1.875rem;
    }
  }

  .content5 {
    width: 100%;
    height: 19.375rem;
    background: #f5f5f5;
  }

</style>